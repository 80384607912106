import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projectDetail: null,
  },
  reducers: {
    setProjectInfo: (state, action) => {
      state.projectDetail = action.payload;
    },
  },
});

export const { setProjectInfo } = projectSlice.actions;
export default projectSlice.reducer;
