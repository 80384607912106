import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Project from "./pages/Project";
import Body from "./components/Body";
import store from "./utils/store";
import ProjectDetail from "./pages/ProjectDetail";
import Landing from "./pages/Landing";
import { ToastContainer } from "react-toastify";
import "./services/index";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";

const contextClass = {
  success: "bg-green-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

const Page404 = () => (
  <div className="w-screen h-screen flex flex-col text-center justify-center items-center ">
    <div className="text-xl md:text-3xl font-md">4 Oh 4! Something isn't right!</div>
    <div className="text-xs md:text-sm font-light">
      Are you sure you entered the correct URL in the address base?
    </div>
  </div>
);

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Body fallback={<p>Something went wrong</p>} />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      // {
      //   path: "/signup",
      //   element: <SignUp />,
      // },
      // {
      //   path: "/signin",
      //   element: <SignIn />,
      // },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/project-detail/:projectId",
        element: <ProjectDetail />,
      },
      {
        path: "/project",
        element: <Project />,
      },
    ],
    errorElement: <Page404 />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/contact",
    element: <Contact />,
  }
]);

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      {/* bg-[radial-gradient(ellipse_at_right,_var(--tw-gradient-stops))] from-gray-700 via-gray-900 to-black */}
      {/* bg-gradient-to-r from-slate-900 to-slate-700 text-gray-300*/}
      <>
        <div className="font-roboto bg-white text-gray-900">
          <RouterProvider router={appRouter} />
        </div>
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          icon={false}
          toastClassName={(context) =>
            contextClass[context?.type || "default"] +
            " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
          }
          bodyClassName={() => "text-sm font-white font-med block p-3"}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
