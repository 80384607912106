import { MEGATRON_ENDPOINT } from "../utils/constants";

const signUpService = async (user) => {
  const resource = MEGATRON_ENDPOINT + "/user/signup";
  const headers = {
    "Content-Type": "application/json",
  };
  const config = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(user),
  };

  const response = await fetch(resource, config);
  const data = await response.json();

  if (response.status >= 200 && response.status <= 300) {
    return data;
  } else {
    throw Error(data);
  }
};

const signInService = async (user) => {
  const response = await fetch(MEGATRON_ENDPOINT + "/user/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  const data = await response.json();
  if (response.status >= 200 && response.status <= 300) {
    return data;
  } else {
    // eslint-disable-next-line no-throw-literal
    throw { ...data };
  }
};

const GoogleAuthService = async (googleAccessToken) => {
  const resource = MEGATRON_ENDPOINT + "/user/google";
  const headers = {
    "Content-Type": "application/json",
  };
  const config = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ googleAccessToken: googleAccessToken }),
  };

  const response = await fetch(resource, config);
  const data = await response.json();

  if (response.status >= 200 && response.status <= 300) {
    return data;
  } else {
    throw Error(data);
  }
};

export { signUpService, signInService, GoogleAuthService };
