import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  generateVideoChaptersService,
  generateVideoDescriptionService,
  generateVideoHashtagService,
  generateVideoSubtitleService,
  generateVideoTagService,
  generateVideoTitleService,
  getProjectByIdService,
} from "../services/project";
import { setProjectInfo } from "../utils/slice/projectSlice";
import Button from "../components/Button";
import { toast } from "react-toastify";
import CopyButton from "../components/CopyButton";
import Feedback from "../components/Feedback";
import Tab from "../components/Tab";

const ProjectDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { projectId } = useParams();
  const [showFeedback, setShowFeedback] = useState(null);

  const projectData = useSelector((store) => store.project.projectDetail);
  const token =
    useSelector((store) => store?.user?.userInfo?.token) ||
    JSON.parse(localStorage?.getItem("userDetails"))?.token ||
    null;
  const user = useSelector((store) => store?.user?.userInfo) || null;
  if (token === null) {
    navigate("/");
  }
  const [project, setProject] = useState(projectData || {});

  useEffect(() => {
    async function initProject() {
      const data = await getProjectByIdService(token, projectId);
      dispatch(setProjectInfo(data.data));
      setProject(data.data);
    }
    if (token && projectId) {
      initProject();
    } else {
      navigate("/");
    }
  }, []);

  const generateTitle = async () => {
    if (projectId) {
      const payload = { projectId: projectId };
      const data = await generateVideoTitleService(token, payload);
      setProject({ ...projectData, videoTitle: data.data });
      dispatch(setProjectInfo({ ...projectData, videoTitle: data.data }));
      setShowFeedback("title");
    } else {
      console.log("Project ID null: ", projectId);
    }
  };
  const generateDescription = async () => {
    if (projectId) {
      const payload = { projectId: projectId };
      const data = await generateVideoDescriptionService(token, payload);
      setProject({ ...projectData, videoDescription: data.data });
      dispatch(setProjectInfo({ ...projectData, videoDescription: data.data }));
      setShowFeedback("description");
    } else {
      console.log("Project ID null: ", projectId);
    }
  };
  const generateTags = async () => {
    if (projectId) {
      const payload = { projectId: projectId };
      const data = await generateVideoTagService(token, payload);
      setProject({ ...projectData, videoTags: data.data });
      dispatch(setProjectInfo({ ...projectData, videoTags: data.data }));
      setShowFeedback("tags");
    } else {
      console.log("Project ID null: ", projectId);
    }
  };

  const generateHashtags = async () => {
    if (projectId) {
      const payload = { projectId: projectId };
      const data = await generateVideoHashtagService(token, payload);
      setProject({ ...projectData, videoHashtags: data.data });
      dispatch(setProjectInfo({ ...projectData, videoHashtags: data.data }));
      setShowFeedback("hashtags");
    } else {
      console.log("Project ID null: ", projectId);
    }
  };

  // const generateSubtitles = async () => {
  //   if (projectId) {
  //     const payload = { projectId: projectId };
  //     const data = await generateVideoSubtitleService(token, payload);
  //     setProject({ ...projectData, subtitleVTT: data.data });
  //     dispatch(setProjectInfo({ ...projectData, subtitleVTT: data.data }));
  //     setShowFeedback("subtitles");
  //   } else {
  //     console.log("Project ID null: ", projectId);
  //   }
  // };

  const formatSubtitles = (subtitles) => {
    return subtitles
      .map((subtitle) => `${subtitle.start} --> ${subtitle.end} \n- ${subtitle.text} \n\n`)
      .join("\r\n");
  };

  const generateChapters = async () => {
    if (projectId) {
      const payload = { projectId: projectId };
      const data = await generateVideoChaptersService(token, payload);
      setProject({ ...projectData, videoChapters: data.data });
      dispatch(setProjectInfo({ ...projectData, videoChapters: data.data }));
      setShowFeedback("chapters");
    } else {
      console.log("Project ID null: ", projectId);
    }
  };

  return (
    <div className="pt-[80px] lg:pt-[10px] w-screen h-full pb-4 grid grid-cols-12 lg:px-[200px] text-gray-700">
      {projectData?.transcoding && projectData.transcoding.length > 0 ? (
        <div className="mx-2 col-span-12 p-4 text-green-900 border border-green-500 rounded-lg bg-green-50">
          Your video is processed and ready!
        </div>
      ) : projectData?.transcodingError ? (
        <div className="col-span-12 p-4 text-rose-900 border border-rose-500 rounded-lg bg-rose-50">
          Sanchay.ai requires videos with audible speech to generate subtitles, descriptions, tags,
          and more.
          <br />
          Since your video does not contain speech in its audio, the transcription process did not
          work. Hence, Some features will not work properly.
        </div>
      ): <div className="mx-2 col-span-12 p-4 text-blue-900 border border-blue-500 rounded-lg bg-blue-50">
      Your video is being processed. Please wait
    </div>}
      <div className="order-2 md:order-1 p-4 col-span-12 md:col-span-8">
        {/* <h1 className="text-xl py-2 ">Video details</h1> */}
        <Tab title="Title" showDefault={false} isDone={projectData?.videoTitle?.length > 0}>
          <textarea
            type="text"
            value={projectData?.videoTitle}
            className="text-gray-800 py-2 px-3 bg-transparent h-[100px] md:h-auto border-0 outline-none w-full resize-none font-semibold text-md lg:text-lg"
          ></textarea>

          <div className="flex justify-between items-center text-gray-500 text-sm p-2">
            <div className="flex items-center justify-start">
              <Button
                disabled={projectData?.transcoding?.length === 0}
                value={projectData?.videoDescription ? "Re-generate using AI" : "Generate using AI"}
                type="tertiary"
                onClickHandler={generateTitle}
              />
              <CopyButton
                value={projectData?.videoTitle}
                disabled={projectData?.transcoding?.length === 0}
                onClickHandler={() => toast.success("Copied to clipboard")}
              />
            </div>
            {showFeedback === "title" ? (
              <Feedback
                onFeedbackSubmitHandler={() => setShowFeedback(false)}
                data={{
                  feature: "Title",
                  value: projectData?.videoTitle,
                  video: projectData.video_file,
                  projectId: projectData._id,
                  user: user,
                }}
              />
            ) : (
              <div></div>
            )}
            <div>{projectData?.videoTitle?.length || 0} / 100</div>
          </div>
        </Tab>
        <Tab title="Description" showDefault={false} isDone={projectData?.videoDescription}>
          <textarea
            value={projectData?.videoDescription}
            className="text-sm h-[400px] py-2 px-3 bg-transparent border-0 outline-none w-full resize-none"
          ></textarea>

          <div className="flex justify-between items-center text-gray-500 text-sm p-2">
            <div className="flex items-center justify-start">
              <Button
                value={projectData?.videoDescription ? "Re-generate using AI" : "Generate using AI"}
                disabled={projectData?.transcoding?.length === 0}
                type="tertiary"
                onClickHandler={generateDescription}
              />
              <CopyButton
                value={projectData?.videoDescription}
                disabled={projectData?.transcoding?.length === 0}
                onClickHandler={() => toast.success("Copied to clipboard")}
              />
            </div>
            {showFeedback === "description" ? (
              <Feedback
                onFeedbackSubmitHandler={() => setShowFeedback(false)}
                data={{
                  feature: "Description",
                  value: projectData?.videoDescription,
                  video: projectData.video_file,
                  projectId: projectData._id,
                  user: user,
                }}
              />
            ) : (
              <div></div>
            )}
            <div>{projectData?.videoDescription?.length || 0} / 5000</div>
          </div>
        </Tab>
        <Tab title="Tags" showDefault={false} isDone={projectData?.videoTags}>
          <div className="flex flex-wrap p-4">
            {projectData?.videoTags?.split(",")?.map((tag) => (
              <div key={tag} className="px-2 py-1 border border-gray-500 rounded-md m-2">
                {tag}
              </div>
            ))}
          </div>

          <div className="flex justify-between items-center text-gray-500 text-sm p-2">
            <div className="flex items-center justify-start">
              <Button
                value={projectData?.videoTags ? "Re-generate using AI" : "Generate using AI"}
                type="tertiary"
                disabled={projectData?.transcoding?.length === 0}
                onClickHandler={generateTags}
              />
              <CopyButton
                value={projectData?.videoTags}
                disabled={projectData?.transcoding?.length === 0}
                onClickHandler={() => toast.success("Copied to clipboard")}
              />
            </div>
            {showFeedback === "tags" ? (
              <Feedback
                onFeedbackSubmitHandler={() => setShowFeedback(false)}
                data={{
                  feature: "Tags",
                  value: projectData?.videoTags,
                  video: projectData.video_file,
                  projectId: projectData._id,
                  user: user,
                }}
              />
            ) : (
              <div></div>
            )}
            <div>{projectData?.videoTags?.split(",")?.length || 0} / 500</div>
          </div>
        </Tab>

        <Tab title="Transcription" showDefault={false} isDone={projectData?.transcoding?.length > 0}>
          <textarea
            disabled
            value={project?.transcoding}
            className="text-sm py-2 h-[400px] px-3 bg-transparent border-0 outline-none w-full resize-none"
          ></textarea>
          <div className="flex items-center justify-start m-4">
            <CopyButton
              disabled={projectData?.transcoding?.length === 0}
              value={projectData?.transcoding}
              onClickHandler={() => toast.success("Copied to clipboard")}
            />
          </div>
        </Tab>

        {projectData?.videoSubtitles && (
          <Tab title="Subtitles" showDefault={false} isDone={projectData?.videoSubtitles?.length > 0}>
            <textarea
              disabled={true}
              value={formatSubtitles(projectData?.videoSubtitles)}
              className="text-sm h-[400px] py-2 px-3 bg-transparent border-0 outline-none w-full resize-none"
            ></textarea>
            <div className="flex items-center justify-start m-4">
              {/* <Button value="Generate using AI" type="tertiary" onClickHandler={generateSubtitles} /> */}
              <CopyButton
                value={formatSubtitles(projectData?.videoSubtitles)}
                onClickHandler={() => toast.success("Copied to clipboard")}
              />
            </div>
          </Tab>
        )}
        <Tab title="Thumbnail" showDefault={false}>
          <div className="text-sm p-3">Coming soon</div>
        </Tab>
      </div>
      <div className="col-span-12 md:col-span-4 p-4 w-full order-1 md:order-2">
        {/* <h1 className="text-xl py-2 ">Video</h1> */}
        <div className="my-4 border border-gray-100 bg-white rounded-md w-full aspect-video">
          <video className="w-full h-full rounded-md" controls>
            <source src={projectData?.video_file || ""} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </div>

        <Tab title="Hashtags" showDefault={false} isDone={projectData?.videoHashtags?.length > 0}>
          <textarea
            value={projectData?.videoHashtags}
            className="text-sm p-3 bg-transparent h-[200px] border-0 outline-none w-full resize-none"
          ></textarea>
          <div className="flex items-center justify-start m-4">
            <Button
              value={projectData?.videoHashtags ? "Re-generate using AI" : "Generate using AI"}
              type="tertiary"
              disabled={projectData?.transcoding?.length === 0}
              onClickHandler={generateHashtags}
            />
            <CopyButton
              value={projectData?.videoHashtags}
              disabled={projectData?.transcoding?.length === 0}
              onClickHandler={() => toast.success("Copied to clipboard")}
            />
          </div>
        </Tab>

        {projectData?.videoSubtitles && (
          <Tab title="Video Segments / Chapters" showDefault={false} isDone={projectData?.videoChapters?.length > 0}>
            <>
              {projectData?.videoSubtitles && (
                <textarea
                  disabled={true}
                  value={projectData?.videoChapters}
                  className={`text-sm p-3 bg-transparent h-${projectData?.videoChapters? '[300px]': 'auto'} border-0 outline-none w-full resize-none`}
                ></textarea>
              )}
              {projectData?.videoSubtitles ? (
                <div className="flex items-center justify-start m-4">
                  <Button
                    value={
                      projectData?.videoChapters ? "Re-generate using AI" : "Generate using AI"
                    }
                    type="tertiary"
                    disabled={projectData?.videoSubtitles?.length === 0}
                    onClickHandler={generateChapters}
                  />
                  <CopyButton
                    value={projectData?.videoChapters}
                    disabled={projectData?.videoSubtitles?.length === 0}
                    onClickHandler={() => toast.success("Copied to clipboard")}
                  />
                </div>
              ) : (
                <div className="text-sm p-3 text-gray-600 font-light">
                  This feature is available only when subtitles are generated.
                </div>
              )}
            </>
          </Tab>
        )}
        {/* Original file name
        <div>
          <div className="px-2 text-xs truncate text-ellipsis">
            {projectData?.video_url?.split("/")[projectData?.video_url?.split("/").length - 1]}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ProjectDetail;
