import React, { useState } from "react";

const Tab = ({ showDefault, title, children, isDone }) => {
  const [show, setShow] = useState(showDefault || false);
  return (
    <div className="my-4 border border-gray-100  rounded-md px-4 py-2 shadow-sm shadow-gray-200">
      <div
        className="text-gray-800 tracking-wide text-md font-md p-2 cursor-pointer flex justify-between items-center"
        onClick={() => setShow(!show)}
      >
        <div className="flex">
          <img className="w-6 mr-2" src={isDone ? "/complete.svg" : "/new.svg"} alt="status" />{" "}
          {title}
        </div>
        <span>{show ? "⍲" : "⍱"}</span>
      </div>
      {show && children}
    </div>
  );
};

export default Tab;
