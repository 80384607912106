import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="p-4">
      <h1 className="py-2 text-xl font-semibold">Privacy Policy</h1>
      <p className="py-1 text-md text-gray-700">
        Sanchay.ai ("Company," "we," or "us") is committed to protecting the privacy of our users
        ("you" or "your"). This Privacy Policy explains how we collect, use, and share information
        about you through our website, https://sanchay.ai (the "Website"), and the services we offer
        (the "Services"). By using the Website or Services, you consent to the collection, use, and
        sharing of your information as described in this Privacy Policy.
      </p>

      <h1 className="py-2 text-xl font-semibold">Information We Collect</h1>
      <p className="py-1 text-md text-gray-700">
        We collect information you provide to us when you create an account on our Website,
        including your email address. We also collect information about your usage of our Services,
        including the YouTube channels you connect to the Service, and demographic data using
        plausible.io.
      </p>

      <h1 className="py-2 text-xl font-semibold">Use of Your Information</h1>
      <p className="py-1 text-md text-gray-700">
        We use the information we collect to provide, maintain, and improve our Services, to
        communicate with you, and to better understand the demographics of our users. We may also
        use the information to send you promotional and marketing materials.
      </p>

      <h1 className="py-2 text-xl font-semibold">Sharing of Your Information</h1>
      <p className="py-1 text-md text-gray-700">
        We may share your information with third-party service providers to provide, maintain, and
        improve our Services, such as Stripe for processing payments. We may also share your
        information in response to legal requests, such as a subpoena or court order, or in response
        to a government request. We may also share your information in the event of a merger,
        acquisition, or sale of all or a portion of our assets.
      </p>

      <h1 className="py-2 text-xl font-semibold">Data Retention and Deletion</h1>
      <p className="py-1 text-md text-gray-700">
        We retain your information for as long as your account is active or as needed to provide you
        with our Services. If you wish to delete your entire data, you may send a request to
        hello@sanchay.ai, and we will process your request in accordance with applicable laws.
      </p>

      <h1 className="py-2 text-xl font-semibold">Changes to this Privacy Policy</h1>
      <p className="py-1 text-md text-gray-700">
        We may update this Privacy Policy from time to time. We will notify you of any changes by
        posting the new Privacy Policy on this page.
      </p>

      <h1 className="py-2 text-xl font-semibold">Contact Us</h1>
      <p className="py-1 text-md text-gray-700">
        If you have any questions about this Privacy Policy or our practices related to your
        information, please contact us at hello@sanchay.ai.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
