import { toast } from "react-toastify";
const originalFetch = window.fetch;

const requestInterceptor = (requestArguments) => {
  return requestArguments;
};

const responseInterceptor = async (response) => {
  if (response.ok) {
    return response;
  } else {
    const data = await response.json();
    toast.error(data.error);
    return;
  }
};

// perform all the pre-request actions
window.requestInterceptor = (args) => {
  // your action goes here
  return args;
};

// response interceptor
// perform all the post-response actions
window.responseInterceptor = (response) => {
  return response;
};

// over-ride the original fetch
window.fetch = async (...args) => {
  // pass the args to request interceptor
  args = requestInterceptor(args);
  // pass the updated args to fetch
  let response;
  response = await originalFetch(...args);
  response = responseInterceptor(response);
  return response;

  // response interceptor
  // pass the response to response interceptor

  // return the updated response
};
