import { MEGATRON_ENDPOINT } from "../utils/constants";

const createProjectService = async (token, formData) => {
  const response = await fetch(MEGATRON_ENDPOINT + "/project/new", {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: formData,
  });
  const data = await response?.json();
  return data;
};
const removeProjectService = async (token, projectId) => {
  const response = await fetch(MEGATRON_ENDPOINT + `/project/remove/${projectId}`, {
    method: "DELETE",
    headers: {
     "Content-Type": "application/json",
      Authorization: token,
    },
  });
  const data = await response?.json();
  return data;
};

const getProjectList = async (token) => {
  const response = await fetch(MEGATRON_ENDPOINT + `/project/list`, {
    headers: {
      Authorization: token,
    },
  });
  const data = await response?.json();
  return data;
};

const getProjectByIdService = async (token, projectId) => {
  const response = await fetch(MEGATRON_ENDPOINT + `/project/details?projectId=${projectId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  const projectData = await response?.json();
  return projectData;
};

const generateVideoTitleService = async (token, payload) => {
  const response = await fetch(MEGATRON_ENDPOINT + "/project/generate-title", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload),
  });
  const title = await response?.json();
  return title;
};

const generateVideoDescriptionService = async (token, payload) => {
  const response = await fetch(MEGATRON_ENDPOINT + "/project/generate-description", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload),
  });
  const title = await response?.json();
  return title;
};

const generateVideoTagService = async (token, payload) => {
  const response = await fetch(MEGATRON_ENDPOINT + "/project/generate-tags", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload),
  });
  const title = await response?.json();
  return title;
};

const generateVideoHashtagService = async (token, payload) => {
  const response = await fetch(MEGATRON_ENDPOINT + "/project/generate-hashtags", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload),
  });
  const title = await response?.json();
  return title;
};

const generateVideoSubtitleService = async (token, payload) => {
  const response = await fetch(MEGATRON_ENDPOINT + "/project/generate-subtitles", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload),
  });
  const title = await response?.json();
  return title;
};

const generateVideoChaptersService = async (token, payload) => {
  const response = await fetch(MEGATRON_ENDPOINT + "/project/generate-chapters", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload),
  });
  const title = await response?.json();
  return title;
};

export {
  createProjectService,
  removeProjectService,
  getProjectList,
  getProjectByIdService,
  generateVideoTitleService,
  generateVideoDescriptionService,
  generateVideoTagService,
  generateVideoHashtagService,
  generateVideoSubtitleService,
  generateVideoChaptersService
};
