import React from "react";
import Pricing from "../components/Pricing";
import FAQ from "../components/FAQ";
import Features from "../components/Features";
import SanchayWhy from "../components/SanchayWhy";
import Footer from "../components/Footer";
import Hero from "../components/Hero";

const Landing = () => {
  
  return (
    <>
      <Hero />
      {/* <SanchayWhy /> */}
      <Features />
      {/* <Pricing /> */}
      <FAQ />
      <Footer />
    </>
  );
};

export default Landing;
