import React from "react";

const CopyButton = ({ disabled, value, onClickHandler }) => {
  return (
    <button
      className="disabled:cursor-not-allowed disabled:bg-opacity-50 ml-2 rounded-mg rounded-md p-2 border border-gray-600 bg-white hover:bg-gray-100 text-gray-900 flex"
      disabled={disabled}
      onClick={() => {
        navigator.clipboard.writeText(value);
        onClickHandler();
      }}
    >
      Copy <img src="/copy.svg" className="w-6 h-6" alt="copy" />
    </button>
  );
};

export default CopyButton;
