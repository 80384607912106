import React from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const FeatureList = [
  {
    title: "Automated Video Transcription",
    description:
      "Effortlessly convert your videos into text transcriptions with accurate timestamps.",
  },
  {
    title: "Video Title Generation",
    description:
      "Generate catchy and SEO-friendly titles for your videos to attract more viewers and improve search rankings.",
  },
  {
    title: "Engaging Descriptions",
    description:
      "Create compelling descriptions for your videos that captivate your audience and entice them to click.",
  },
  {
    title: "Optimized Tags and Hashtags",
    description:
      "Discover the most relevant tags and hashtags for your videos to increase visibility and reach a broader audience.",
  },
  {
    title: "Automatic Subtitle Generation",
    description:
      "Automatically add subtitles to your videos for better accessibility and to enhance user engagement.",
  },
  {
    title: "Segmentation for Enhanced Content Organization",
    description:
      "Efficiently organize your videos into chapters or segments for easier navigation and improved user experience.",
  },
];

const Features = () => {
  const token = useSelector((store) => store?.user?.userInfo?.token) || null;
  const navigate = useNavigate();
  return (
    <div className="child-scroll w-screen min-h-screen pt-[100px] flex justify-center items-center text-gray-900 flex-col md:px-[200px] bg-white">
      <div className="md:w-4/6 px-4">
        <h1 className="my-2 md:my-8 mx-2 md:mx-0 font-semibold text-lg text-center md:text-left md:text-4xl">
          Features that will 10x Your Content Creation Journey
        </h1>
        <div className="grid grid-cols-12 md:gap-4">
          {FeatureList.map((feat, index) => (
            <Feature key={index} title={feat.title} description={feat.description} index={index} />
          ))}
        </div>

        {/* <div className="flex flex-col justify-center items-center border border-gray-900 rounded-lg p-4 md:px-8 my-2">
          <h1 className="text-md md:text-2xl font-md p-4 pb-0 text-center">
            So, why wait? Start your journey with Sanchay
            <span className="text-red-600 font-bold">.ai</span> today and soar to new heights
            <br />
            while letting our smart AI handle all the backend work.
          </h1>
          <Button
            value="Go to dashboard"
            type="secondary"
            onClickHandler={() => {
              navigate(token ? "/dashboard" : "/signup");
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

const Feature = ({ title, description, index }) => {
  return (
    <div className="p-4 border border-gray-200 rounded-md mb-2 col-span-12 md:col-span-6">
      <div className="font-bold">{title}</div>
      <div className="text-gray-600 py-2">{description}</div>
    </div>
  );
};
export default Features;
