import React, { useState } from "react";

const list = [
  {
    title: "What is the maximum file size that can be processed by Sanchay.ai?",
    body: "Sanchay.ai works best with file size less than 500mb. Large file sizes may require longer processing times.",
  },
  {
    title: "Is there a video duration limit for Sanchay.ai's services?",
    body: "Sanchay.ai does not impose a video duration limit. However, longer videos may require more processing time.",
  },
  {
    title: "What languages are supported by Sanchay.ai?",
    body: "Sanchay.ai currently supports a wide range of languages, including but not limited to English, Hindi, Spanish, French, German, Italian, and many more. English works best. Other languages are still in the experimental stage.",
  },
  {
    title: "Does Sanchay.ai offer multi-language support for videos?",
    body: "Yes, Sanchay.ai provides multi-language support. You can input videos containing multiple languages, such as a combination of Hindi and English.",
  },
  {
    title: "Are there any additional charges for multi-language support?",
    body: "No, Sanchay.ai does not charge extra for multi-language support. You can avail this feature without any additional cost."
  },
  {
    title: "Can I upload and process multiple videos simultaneously?",
    body: "Yes, you can upload and process multiple videos simultaneously. Sanchay.ai allows batch processing to handle multiple videos efficiently."
  },
  {
    title: "How accurate are the transcriptions and subtitles generated by Sanchay.ai?",
    body: "Sanchay.ai utilizes state-of-the-art AI models to generate transcriptions and subtitles. While accuracy is high, it may vary depending on factors like audio quality and speaker accents."
  },
  {
    title: "Can I edit or refine the automatically generated video titles, descriptions, and tags?",
    body: "Yes, Sanchay.ai allows you to edit or refine the automatically generated video titles, descriptions, and tags to match your specific requirements and preferences."
  },
  {
    title: "Is there a limit to the number of segments generated for a video by Sanchay.ai?",
    body: "Sanchay.ai does not impose a specific limit on the number of segments generated. The segmentation is based on the content of the video and can vary depending on its duration and complexity."
  },
  {
    title: "How many videos am I allowed to upload in a month on Sanchay.ai?",
    body: "Sanchay.ai does not limit the number of videos you can upload in a month. You can upload as many videos as you need within your subscription plan or based on the resources available."
  },
  {
    title: "How many times can I regenerate the titles, descriptions, tags, and other data for a video?",
    body: "With Sanchay.ai, you can regenerate the titles, descriptions, tags, and other data as many times as needed until you achieve the desired result. There is no specific limit on the number of regenerations."
  },
  {
    title: "What type of videos are allowed to be processed by Sanchay.ai?",
    body: "Sanchay.ai supports processing various types of videos, including but not limited to movies, documentaries, interviews, webinars, educational content, and promotional videos. It is designed to handle a wide range of video content."
  }
];
const FAQ = () => {
  return (
    <div className="child-scroll w-screen min-h-screen md:pt-[100px] md:flex justify-center items-center flex-col md:px-[200px] bg-white">
      <div className="md:w-3/5">
        <Tab title="Frequently asked questions" tablist={list} />
      </div>
    </div>
  );
};

const Tab = ({ tablist, title }) => {
  const [activeTab, setActiveTab] = useState(null);

  const onTabClick = (currentIndex) =>{
    if(currentIndex ===activeTab){
        setActiveTab(null)
    }
    else{
        setActiveTab(currentIndex)
    }
  }
  return (
    <div className="p-2">
      <h1 className="text-2xl md:text-6xl font-bold md:font-normal p-4 ">{title}</h1>
      {tablist.length > 0 && (
        <div className="">
          {tablist.map((tab, index) => (
            <div className="p-2" key={index}>
              <div
                className={`hover:underline cursor-pointer md:font-bold ${
                  activeTab !== index ? "border-b border-gray-200" : ""
                } p-2`}
                onClick={() => onTabClick(index)}
              >
                {tab.title}
              </div>
              {activeTab === index && <div className="p-4 pl-2">{tab.body}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FAQ;
