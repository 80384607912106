import React from 'react'

import GoogleSignupBtn from './GoogleSignup';

const Hero = () => {
  return (
    <div className="child-scroll w-screen min-h-screen md:min-h-auto md:pt-[200px] flex justify-center md:justify-start items-center flex-col md:px-[200px] bg-white">
        <div className="md:w-4/5 p-4 text-center">
          <h1 className="text-rose-600 text-lg py-4 md:text-2xl font-bold md:font-semibold md:py-2">
            Video creation doesn't get easier than this
          </h1>
          <h1 className="font-extrabold md:font-bold text-gray-800 text-3xl md:text-6xl py-2">
            Never worry about{" "}
          </h1>
          <div className="text-3xl font-extrabold  md:font-light md:text-5xl [text-wrap:balance] bg-clip-text text-transparent bg-gradient-to-r from-slate-800/60 to-50% to-slate-900">
            <div className="text-gray-800 text-center text-3xl md:text-6xl font-extrabold md:font-light inline-flex flex-col h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] md:h-[84px] overflow-hidden">
              <ul className="block text-center animate-text-slide-5 leading-tight [&_li]:block text-rose-500 md:text-gray-900">
                <li className="p-2">Video Titles</li>
                <li className="p-2">Video Descriptions</li>
                <li className="p-2">Video Tags</li>
                <li className="p-2">Video Subtitles</li>
                <li className="p-2">Video Hashtags</li>
                <li className="p-2">Video Segments / Chapters</li>
              </ul>
            </div>
          </div>
          <h1 className="text-gray-800 font-extrabold text-3xl md:text-6xl md:font-bold py-2">
            again.
          </h1>
          <p className="text-gray-800 text-md md:text-base pt-12 md:pt-4">
            On YouTube, there is already a lot to worry about.{" "}
            <strong className="text-black">
              Sanchay<span className="text-red-600">.ai</span>
            </strong>{" "}
            is on a mission to <i>automate the Monotonous</i>,{" "}
          </p>
          <p className="text-gray-600 text-md md:text-base pb-2">Making Video Creation a breeze.</p>
          <GoogleSignupBtn title="Open app ->" onClickHandler={() => {}} className="disabled:cursor-not-allowed disabled:bg-opacity-50 hover:bg-gray-700 text-sm lg:text-md bg-gray-900 mt-4 px-4 py-2 rounded-md text-white font-bold" />
        </div>
      </div>
  )
}

export default Hero