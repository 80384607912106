const env = process.env.REACT_APP_ENV
export const MEGATRON_ENDPOINT = env==="development" ? "http://localhost:4000" : "https://megatron-wrcg.onrender.com";
export const featureList = [
  "Transcription",
  "Subtitles",
  "Chapters",
  // "Thumbnail",
  "Tags",
  "Hashtags",
  "Video Title",
  "Video Description"
];
