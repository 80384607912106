import React, { useEffect } from "react";
import Header from "./Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../utils/slice/userSlice";

function Body() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.userInfo) || null;
  if (user === null) {
    const userdata = JSON.parse(localStorage.getItem("userDetails")) || null;
    if (userdata !== null) {
      dispatch(setUserInfo(userdata));
    }
  }
  useEffect(() => {
    const path = location.pathname;
    if (user === null) {
      const userdata = JSON.parse(localStorage.getItem("userDetails")) || null;
      if (userdata === null && path !== "/") {
        navigate("/");
      }
    }
  }, [dispatch, navigate, user, location.pathname]);

  return (
    <div className="parent-scroll">
      <Header />
      <Outlet />
    </div>
  );
}

export default Body;
