import React from "react";

const Contact = () => {
  return (
    <div className="w-screen h-screen flex flex-col text-center justify-center items-center ">
      <div className="text-xl md:text-3xl font-md">hello@sanchay.ai</div>
      <div className="text-xs md:text-sm font-light">
        Feedbacks: <a href="https://forms.gle/Fv7h8EcsTMbDGLag8">Feedback form</a>
      </div>
    </div>
  );
};

export default Contact;
