import React from "react";

const Terms = () => {
  return (
    <div className="p-4">
      <h1 className="py-2 text-xl font-semibold">Terms of Service</h1>
      <p className="py-1 text-md text-gray-700">
        These Terms of Service (the “Terms”) are a legal agreement between you and Sanchay.ai, and
        they govern your use of the Sanchay.ai website and services (collectively, the “Service”).
        By accessing or using the Service, you agree to be bound by these Terms and by the
        Sanchay.ai Privacy Policy, which is incorporated into these Terms by reference. If you do
        not agree to these Terms, you may not use the Service.
      </p>

      <h1 className="py-2 text-xl font-semibold">Accounts</h1>
      <p className="py-1 text-md text-gray-700">
        To access certain features of the Service, you must create an account. You agree to provide
        accurate and complete information when creating your account, and you agree to keep your
        account information up-to-date. You are responsible for all activities that occur under your
        account, including any unauthorized activities. If you suspect that someone has gained
        unauthorized access to your account, you should notify Sanchay.ai immediately.
      </p>

      <h1 className="py-2 text-xl font-semibold">Refunds</h1>
      <p className="py-1 text-md text-gray-700">Refunds for the Service will be given within 7 days of purchase.</p>

      <h1 className="py-2 text-xl font-semibold">No Transfer of Accounts</h1>
      <p className="py-1 text-md text-gray-700">Sanchay.ai does not allow the transfer of accounts to another party.</p>

      <h1 className="py-2 text-xl font-semibold">Commercial Use</h1>
      <p className="py-1 text-md text-gray-700">Commercial use of the Service is allowed.</p>

      <h1 className="py-2 text-xl font-semibold">Use of Company Logos and Names</h1>
      <p className="py-1 text-md text-gray-700">
        By signing up for the Service with a work email address, you grant Sanchay.ai permission to
        use your company's logo and name on our landing page or in our advertisements to indicate
        that your company uses our Service. This permission is conditional upon your continued use
        of the Service.
      </p>

      <h1 className="py-2 text-xl font-semibold">Disclaimer of Warranty and Limitation of Liability</h1>
      <p className="py-1 text-md text-gray-700">
        The Service is provided on an “as is” and “as available” basis. Sanchay.ai does not warrant
        that the Service will be error-free, uninterrupted, or secure, and you use the Service at
        your own risk. Sanchay.ai is not responsible for any legal mishappenings generated by AI.
      </p>

      <h1 className="py-2 text-xl font-semibold">Changes to these Terms of Service</h1>
      <p className="py-1 text-md text-gray-700">
        Sanchay.ai may modify these Terms at any time by posting a revised version on the Service.
        You agree that your continued use of the Service after the effective date of the revised
        Terms constitutes your acceptance of the revised Terms.
      </p>

      <h1 className="py-2 text-xl font-semibold">Contact Us</h1>
      <p className="py-1 text-md text-gray-700">
        If you have any questions about these Terms or the Service, please contact us at
        hello@sanchay.ai
      </p>
    </div>
  );
};

export default Terms;
