import React from "react";

const FileUpload = ({ file, onFileSelected }) => {
  return (
    <div className="md:p-2 flex items-center justify-center w-full">
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-64 border border-gray-200 rounded-lg cursor-pointer bg-gray-100 shadow-sm shadow-gray-200 hover:bg-gray-100"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 16"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
            />
          </svg>
          <div className="mb-2 text-sm text-gray-400 dark:text-gray-300 px-2 text-center">
            <div className="text-green-500 font-md">{file?.name}</div>
            <span className="font-semibold">Click to {file?.name ? "re-" : ""}upload</span> or drag
            and drop
          </div>
          <p className="text-xs text-gray-400 dark:text-gray-400 px-2">
            MP4, MOV, WAV or AVI (MAX. 400MB allowed)
          </p>
        </div>
        <input id="dropzone-file" onChange={onFileSelected} type="file" className="hidden" />
      </label>
    </div>
  );
};

export default FileUpload;
