import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleAuthService } from "../services/auth";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../utils/slice/userSlice";
import { useNavigate } from "react-router-dom";

const GoogleSignupBtn = ({className, title, onClickHandler }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  async function handleGoogleLoginSuccess(tokenResponse) {
    const accessToken = tokenResponse.access_token;
    // const response = await test(accessToken)
    const response = await GoogleAuthService(accessToken);
    dispatch(setUserInfo(response.data));
    localStorage.setItem("userDetails", JSON.stringify(response.data));
    navigate("/dashboard");
    onClickHandler()
  }

  const login = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });
  return (<button className={className} onClick={() => login()}>
        {title ? title: "Sign In with Google"} 
      </button>);
};

export default GoogleSignupBtn;
