import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setUserInfo } from "../utils/slice/userSlice";
import { useLocation } from "react-router-dom";
import GoogleSignupBtn from "./GoogleSignup";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const user = useSelector((store) => store.user.userInfo);
  const onLogoutClicked = () => {
    dispatch(setUserInfo(null));
    navigate("/");
    localStorage.clear("userDetails");
  };
  const onMobileMenuClicked = (path) => {
    navigate(path);
    setShowMenu(false);
  };
  return (
    <div
      className={`absolute md:sticky top-0 bg-white md:shadow-sm left-0 w-screen flex flex-col md:flex-row justify-between items-center p-4 md:px-12 text-gray-900`}
    >
      <div className="flex flex-col md:flex-row items-center">
        <Link
          to={`/`}
          className="has-tooltip relative text-2xl tracking-wide font-light cursor-pointer"
        >
          Sanchay
          <span className="text-rose-600 font-bold relative">
            .ai <sub className="text-black text-xs absolute bottom-0 -right-3">β</sub>
          </span>
          <span className="tooltip absolute text-sm w-[180px] rounded border border-gray-100 shadow-sm p-1 bg-gray-50 text-gray-700 mb-8">
            Sanchay AI Beta version
          </span>
        </Link>

        <div className="ml-8 mt-2 hidden md:block">
          {user?.token && (
            <Link
              className="hover:text-gray-600 tracking-wide cursor-pointer text-sm text-gray-800 mx-2 hover:border-b border-gray-800 p-2"
              to="/dashboard"
            >
              Dashboard
            </Link>
          )}{" "}
          {user?.token && (
            <Link
              className="hover:text-gray-600 tracking-wide cursor-pointer text-sm text-gray-800  mx-2 hover:border-b border-gray-800 p-2"
              to="/project"
            >
              New Project
            </Link>
          )}
          {user?.token && (
            <a
              className="hover:text-gray-600 tracking-wide cursor-pointer text-sm text-gray-800  mx-2 hover:border-b border-gray-800 p-2"
              href="https://forms.gle/Fv7h8EcsTMbDGLag8"
            >
              Feedback
            </a>
          )}
        </div>
      </div>
      {user ? (
        <div
          onClick={onLogoutClicked}
          className="hover:bg-gray-100 hidden my-4 md:my-0 tracking-wide text-md text-gray-800 md:flex justify-center items-center cursor-pointer p-2 text-sm rounded-md font-medium"
        >
          <img src={user.profilePicture} className="rounded-full w-8 h-8 mx-2 mr-3 " alt="user" />{" "}
          Logout
        </div>
      ) : (
        <div className="mt-2 md:mt-0">
          {user === null && (
            <GoogleSignupBtn
              className="hidden md:block tracking-wide text-md text-gray-800 border cursor-pointer border-gray-400 p-2 text-sm rounded-md font-medium"
              title="Get Started →"
              onClickHandler={() => {}}
            />
          )}
        </div>
      )}
      <div
        onClick={() => setShowMenu(true)}
        className="md:hidden absolute top-5 right-5 cursor-pointer"
      >
        <div className="w-8 border rounded-full border-black mb-2"></div>
        <div className="w-8 border rounded-full border-black mb-2"></div>
        <div className="w-8 border rounded-full border-black"></div>
      </div>
      {showMenu && (
        <div className="fixed top-0 left-0 bg-white w-screen h-screen overscroll-none z-[100000] p-8">
          <button className="text-xl absolute top-5 right-5" onClick={() => setShowMenu(false)}>
            ╳
          </button>
          <h1 className="p-2 mt-12 text-3xl font-extrabold">
            Sanchay<span className="text-red-600 font-bold">.ai</span>
          </h1>
          <ul className="p-2 w-full">
            {user?.token && (
              <li
                onClick={() => onMobileMenuClicked("/dashboard")}
                className="py-4 px-2 font-semibold text-lg border-y border-gray-100"
              >
                Dashboard
              </li>
            )}
            {user?.token && (
              <li
                onClick={() => onMobileMenuClicked("/project")}
                className="py-4 px-2 font-semibold text-lg border-y border-gray-100"
              >
                New Project
              </li>
            )}
            {user?.token && (
              <li className="py-4 px-2 font-semibold text-lg border-y border-gray-100">
                <a href="https://forms.gle/Fv7h8EcsTMbDGLag8">Feedback</a>
              </li>
            )}
            {user ? (
              <li
                onClick={() => {
                  setShowMenu(false);
                  onLogoutClicked();
                }}
                className="py-4 px-2 font-semibold text-lg border-y border-gray-100"
              >
                Logout
              </li>
            ) : (
              <>
                {user === null && (
                  <GoogleSignupBtn
                    className="py-4 px-2 font-semibold text-lg border-y border-gray-100"
                    title="Get Started →"
                    onClickHandler={() => {
                      setShowMenu(false);
                    }}
                  />
                )}
              </>
            )}
          </ul>
          {user?.token && (
            <div className="fixed left-0 p-8 bottom-0 w-screen flex items-center border-t border-gray-100">
              <img
                src={user?.profilePicture}
                className=" border rounded-full w-14 h-14 p-1 mx-2 mr-3 "
                alt="user"
              />
              <div>
                <div className="px-1 font-bold">{user?.email || ""}</div>
                <div className="px-1 font-normal text-gray-500 text-sm">{user?.username || ""}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
