import React, { useMemo } from "react";
import {
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  createColumnHelper,
  flexRender,
} from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProjectInfo } from "../utils/slice/projectSlice";
import { CirclesWithBar } from "react-loader-spinner";
// import data from "../utils/projects.json";

const columnHelper = createColumnHelper();

const ProjectTable = ({ data, removeProject }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    columnHelper.accessor("video_url", {
      header: () => <div className="md:w-[180px]">Video</div>,
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <video
            onClick={() => {
              dispatch(setProjectInfo(rowData));
              navigate(`/project-detail/${rowData.projectId}`);
            }}
            className="md:block w-[180px] aspect-video"
          >
            <source src={info.getValue() || ""} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        );
      },
    }),
    columnHelper.accessor("projectId", {
      header: () => <div className="hidden md:block md:w-[250px]">Description</div>,
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <div
            onClick={() => {
              dispatch(setProjectInfo(rowData));
              navigate(`/project-detail/${rowData.projectId}`);
            }}
            className="font-sm pb-2 hidden md:flex flex-col justify-start items-center"
          >
            <div className="text-black w-full truncate pb-2 font-semibold">
              {rowData?.videoTitle || rowData?.video_name || ""}
            </div>
            <p className="text-gray-600 text-xs w-full line-clamp-2">
              {rowData?.videoDescription || "No description"}
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor("createdAt", {
      header: () => <div className="hidden md:block md:w-[100px]">Date</div>,
      cell: (info) => {
        const date = new Date(info.getValue());
        const options = { year: "numeric", month: "short", day: "numeric" };
        const formattedDate = date.toLocaleDateString("en-US", options);
        return (
          <span className="hidden md:inline text-highlightColor cursor-pointer font-normal w-[60px]">
            {formattedDate || ""}
          </span>
        );
      },
    }),
    columnHelper.accessor("transcoding", {
      header: () => <span>Status</span>,
      cell: (info) => {
        const rowData = info.row.original;
        const labelValue = rowData?.transcodingError?.message ? (
          "Failed"
        ) : rowData?.transcoding ? (
          "Success"
        ) : (
          <div>
            <CirclesWithBar
              height="48"
              width="48"
              color="#4fa94d"
              outerCircleColor="#4fa94d"
              innerCircleColor="#4fa94d"
              barColor="#4fa94d"
              ariaLabel="circles-with-bar-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        );
        return <span className="text-highlightColor font-normal">{labelValue}</span>;
      },
    }),
    columnHelper.accessor("_id", {
      header: () => <span>Actions</span>,
      cell: (info) => {
        return (
          <div
            className="text-highlightColor font-normal flex justify-center items-center"
            onClick={() => removeProject(info.getValue())}
          >
            <img className="w-6 h-6" src="/delete.svg" alt="delete" />
          </div>
        );
      },
    }),
  ];

  return (
    <Table
      {...{
        data,
        columns,
      }}
      page
    />
  );
};

function Table({ data, columns }) {
  const table = useReactTable({
    data,
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    debugTable: true,
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  });
  return (
    <div className="w-full shadow-sm shadow-gray-200">
      <div className="h-2 w-full" />
      <table className="w-full border p-1 md:p-0 border-gray-100 tracking-wide bg-gray-100 bg-opacity-50 rounded-md ">
        <thead className="rounded-sm bg-rose-600 text-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className="text-sm py-[10px] px-3 bg-tableHeader font-normal text-md border-b border-gray-200 text-left"
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="w-full">
          {table.getRowModel().rows.map((row) => {
            return (
              <tr className="hover:bg-gray-200 cursor-pointer" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="text-sm py-[14px] px-3 border-b border-gray-200" key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      {table.getPageCount() > 1 && (
        <div className="flex justify-center items-center gap-2 p-4 bg-gray-100 bg-opacity-50 text-gray-800">
          <button
            className="w-20 border border-gray-200 shadow-sm shadow-gray-100 rounded-md p-2 text-sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"Previous"}
          </button>
          {Array(table.getPageCount())
            .join()
            .split(",")
            .map((_, i) => (
              <div
                key={i}
                onClick={() => table.setPageIndex(i)}
                className={`text-sm cursor-pointer px-2 py-1 rounded-md  ${
                  table.getState().pagination.pageIndex === i
                    ? "bg-sky-700 text-gray-300 border border-gray-200"
                    : "text-gray-800"
                }`}
              >
                {i + 1}
              </div>
            ))}
          <button
            className="w-20 border border-gray-200 shadow-sm shadow-gray-100 rounded-md p-2 text-sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {"Next"}
          </button>
        </div>
      )}
    </div>
  );
}

export default React.memo(ProjectTable);
