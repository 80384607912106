import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FileUpload from "../components/FileUpload";
import { createProjectService } from "../services/project";
import { useSelector } from "react-redux";
import Button from "../components/Button";

const Project = () => {
  const navigate = useNavigate();
  const token = useSelector((store) => store?.user?.userInfo?.token);
  const [projectName, setProjectName] = useState("");
  const [videoFile, setVideoFile] = useState(null);

  const onProjectChanged = (e) => {
    setProjectName(e.target.value);
  };
  const onGenerateClicked = async () => {
    if (projectName.trim() === "") {
      toast.error("Project name cannot be empty.");
    } else if (videoFile === null) {
      toast.error("Video file cannot be empty.");
    }

    else {
      const formData = new FormData();
      formData.append("video_name", projectName);
      formData.append("video_file", videoFile);
      toast.info("Processing your video. Please wait. This might take some time...");
      const response = await createProjectService(token, formData);
      toast.success(response.message);
      navigate("/dashboard");
    }
  };
  const onFileSelected = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file type
      if (!file.type.includes("video/")) {
        toast.error("Invalid file type. Please select a video file.");
        return;
      }
      // Validate file size
      if (file.size > 500 * 1024 * 1024) {
        toast.error("At this moment, Sanchay.ai beta supports 500Mb files only. Your File size exceeds the limit of 500MB.");
        return;
      }
      setVideoFile(event.target.files[0]);
    }
  };
  return (
    <div className="w-screen h-screen flex flex-col mt-10 justify-start items-center">
      <div className="bg-gray-100 md:border border-gray-200 bg-opacity-10 w-full md:w-[600px] md:min-w-[600px] overflow-auto  shadow-gray-200 md:shadow-md p-2 md:p-4 py-6 md:py-8 md:rounded-md">
        <h1 className="text-lg text-gray-700 font-semibold tracking-wide px-4 py-2 border-b border-gray-200 mb-4">
          New Project
        </h1>
        <div className="w-full px-4">
          <div className="text-left px-2 py-2 tracking-wide font-semibold text-gray-700">
            Your Project *
          </div>
          <input
            type="text"
            value={projectName}
            onChange={onProjectChanged}
            className="focus:outline-none focus:outline-1 focus:outline-gray-200 p-4 text-gray-900 tracking-wide placeholder:text-sm placeholder:text-gray-600 bg-gray-100 border border-gray-100 rounded-md font-light w-full my-2"
            placeholder="Enter Project name"
          />
        </div>
        <div className="w-full px-4">
          <div className="text-left px-2 py-2 tracking-wide font-semibold text-gray-700">
            Your Video *
          </div>
          <FileUpload file={videoFile} onFileSelected={onFileSelected} />
        </div>
        <div className="w-full p-4">
        <div className="text-left px-2 py-2 tracking-wide font-semibold text-gray-700">
            Note: 
          </div>
          <p className="px-2">Our app relies on the audio from your video to generate all necessary details such as title, description, tags, subtitles, and chapters. Please ensure your video has clear audio for the best results.</p>
        </div>
        <div className="px-4">
          <Button value="Generate Now!" type="primary" onClickHandler={onGenerateClicked} />
          {/* <button
          onClick={onGenerateClicked}
          className="p-3 w-full text-sm tracking-wide rounded-lg bg-rose-600 shadow-sm shadow-gray-200 text-white my-2"
        >
          Generate Now!
        </button> */}
        </div>
      </div>
    </div>
  );
};

export default Project;
