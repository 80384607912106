import React, { useState } from "react";
import { toast } from "react-toastify";
import { feedbackService } from "../services/feedback";
import { useSelector } from "react-redux";

const Feedback = ({ data, onFeedbackSubmitHandler }) => {
    const token = useSelector((store) => store?.user?.userInfo?.token) || JSON.parse(localStorage?.getItem("userDetails"))?.token || null;
  const [feedData, setFeedData] = useState({
    improvements: "",
  });

  const setFeedback = (userInput) => {
    setFeedData({ ...feedData, feedback: userInput });
  };

  const onDoneClickHandler = async ()=>{
    await feedbackService(token, {data, feedData})
    onFeedbackSubmitHandler()
    toast.info("Thanks for the feedback")
  }
  let hideFeedback = true
  if(hideFeedback){
    return
  }
  return (
    <div className="p-4 hidden md:flex">
      <button onClick={() => setFeedback(true)} className={`p-2 border border-gray-200 ${feedData && feedData.feedback === true && 'bg-green-100'} mx-2`}>
        👍🏼
      </button>
      <button onClick={() => setFeedback(false)} className={`p-2 border border-gray-200 ${feedData && feedData.feedback === false && 'bg-red-100'} mx-2`}>
        👎🏼
      </button>
      <input
        className="w-[400px] px-2 border border-gray-200"
        type="text"
        onChange={(e) => setFeedData({ ...feedData, improvements: e.target.value })}
        placeholder="Tell us how this could be improved"
      />
      <button onClick={onDoneClickHandler} className="ml-2 rounded-md p-2 border border-gray-300">Done</button>
    </div>
  );
};

export default Feedback;
