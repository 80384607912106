import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-scroll h-[300px] bg-black text-gray-400 p-8 flex tracking-wide justify-center items-center flex-col">
      <div className="py-2 text-xs">
        Want to give us feedback?{" "}
        <a
          className="hover:text-rose-600 text-rose-400 text-semibold"
          href="https://forms.gle/Fv7h8EcsTMbDGLag8"
        >
          Feedback form
        </a>
      </div>
      <ul className="flex text-sm justify-center items-center p-4">
        <li className="mx-4 cursor-pointer hover:text-gray-300">
          <Link to="/contact">Contact</Link>
        </li>
        <li className="mx-4 cursor-pointer hover:text-gray-300">
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li className="mx-4 cursor-pointer hover:text-gray-300">
          <Link to="/terms">Terms</Link>
        </li>
      </ul>
      <ul className="flex text-sm justify-center items-center p-4">
        <li className="mx-4">
          <a href="https://www.instagram.com/sanchay.ai/">
            <img src="/instagram.svg" className="w-8" alt="instagram" />
          </a>
        </li>
        <li className="mx-4">
          <img src="/twitter.svg" className="w-8" alt="instagram" />
        </li>
      </ul>
      <h1 className="text-2xl text-gray-100 font-light p-4">
        Sanchay<span className="text-red-600 font-bold">.ai</span>
      </h1>
      <span className="text-xs">© 2024</span>
    </footer>
  );
};

export default Footer;
