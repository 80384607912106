const { MEGATRON_ENDPOINT } = require("../utils/constants");

const feedbackService = async (token, formData) => {
  const response = await fetch(MEGATRON_ENDPOINT + "/feedback", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(formData),
  });
  const data = await response.json();
  return data;
};

module.exports = { feedbackService };
