import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import projectsData from "../utils/projects.json";
import ProjectTable from "../components/ProjectTable";
import { getProjectList, removeProjectService } from "../services/project";
import { useSelector } from "react-redux";
import Button from "../components/Button";
const Dashboard = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState(null);
  const token =
    useSelector((store) => store?.user?.userInfo?.token) ||
    JSON.parse(localStorage?.getItem("userDetails"))?.token ||
    null;
  if (token === null) {
    navigate("/");
  }
  useEffect(() => {
    dashSetup();
    const interval = setInterval(dashSetup, 30000); // Polling interval
    return () => clearInterval(interval); // Cleanup
  }, []);
  
  async function dashSetup() {
    if (token) {
      const data = await getProjectList(token);
      const projs = data?.data.map((proj) => {
        return {
          ...proj,
          project_name: proj.video_name,
          video_file: proj.video_url,
          transcoding: proj.transcoding,
          projectId: proj._id,
        };
      });
      
      setProjects(projs);
    } else {
      navigate("/");
    }
  }

  async function removeProject(projectId) {
    if (token) {
      await removeProjectService(token, projectId);
      await dashSetup();
    } else {
      console.log("Token not found");
    }
  }
  if (projects !== null && projects?.length === 0) {
    return <NoProjects />;
  }
  return (projects !== null && projects?.length > 0) && (
    <>
      <div className="pt-[50px] h-screen flex flex-col justify-start items-center">
        <h1 className="text-lg tracking-wide md:text-xl font-semibold my-8 md:my-2">
          Your Projects
        </h1>
        <div className="md:w-3/5 p-4 md:p-0">
          {/* <ProjectList data={projects} /> */}
          <ProjectTable data={projects || []} removeProject={removeProject} />
        </div>
        <h1 className="p-4 text-center">
          For best experience, use the app on desktop <br />
          instead of mobile{" "}
        </h1>
      </div>
    </>
  );
};

const NoProjects = () => {
  const navigate = useNavigate();
  const onStartProjectClicked = () => {
    navigate("/project");
  };
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="md:shadow-md md:border md:border-gray-100 md:bg-gray-50 md:shadow-gray-200 rounded-md p-4 flex justify-center items-center flex-col w-[400px] h-[300px]">
        <img src="/logo192.png" className="w-24" alt="logo" />
        <h1 className="text-md font-light text-gray-600 my-2">No projects created.</h1>
        <Button
          value="Start a new Project"
          onClickHandler={onStartProjectClicked}
          type="tertiary"
        />
      </div>
    </div>
  );
};

export default Dashboard;
